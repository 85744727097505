
import { textSearch } from "@/assets/ts/_utils";
import { toLocaleDateString } from "@/assets/ts/_utils/formatters";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { ServicioListado } from "@/store/modules";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "servicios-listado",
  components: { "download-excel": JsonExcel },
  props: {
    servicios: Object as () => ServicioListado[] | null,
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const csvLabels = {
      responsable: t("csv.listados.servicios.responsable"),
      dni: t("csv.listados.servicios.dni"),
      menoresNombres: t("csv.listados.servicios.menor"),
      fechaNacimiento: t("csv.listados.servicios.fechaNacimiento"),
      direccion: t("csv.listados.servicio.direccion"),
      inicioPrevisto: t("csv.listados.servicios.inicioPrevisto"),
      finPrevisto: t("csv.listados.servicios.finPrevisto"),
      estado: t("csv.listados.servicios.estado"),
    };

    const csvFields = [
      "responsable",
      "dni",
      "menoresNombres",
      "fechaNacimiento",
      "direccion",
      "inicioPrevisto",
      "finPrevisto",
      "estado",
    ];
    const csvName = "serviciosListado" + new Date().getTime() + ".xlsx";

    const search = ref("");

    const sortMenorNombreApellidos = function (a, b) {
      let primero = a.menores
        .map((menor) => menor.nombre + " " + menor.apellidos)
        .join(", ");
      let segundo = b.menores
        .map((menor) => menor.nombre + " " + menor.apellidos)
        .join(", ");
      if (primero > segundo) {
        return 1;
      }
      if (primero < segundo) {
        return -1;
      }
      return 0;
    };

    /*const sortProfesionalNombreApellidos = function (a, b) {
      let primero = a.familia.nombre + " " + a.familia.apellidos;
      let segundo = b.familia.nombre + " " + b.familia.apellidos;
      if (primero > segundo) {
        return 1;
      }
      if (primero < segundo) {
        return -1;
      }
      return 0;
    };*/

    const handleEdit = (index, row) => {
      router.push(`/listados/servicios/${row.id}`);
    };

    const registros = computed(() => {
      return props.servicios?.map((row) => {
        return {
          ...row,
          responsable: row.familia
            ? row.familia.nombre + " " + row.familia.apellidos
            : "",
          dni: row.familia ? row.familia.dni : "",
          menoresNombres: row.menores
            ?.map((menor) => menor.nombre + " " + menor.apellidos)
            .join(", "),
          fechaNacimiento: row.menores
            ?.map((menor) =>
              new Date(menor.fechaNacimiento).toLocaleDateString("es-ES")
            )
            .join(", "),
        };
      });
    });

    return {
      serviciosListado: computed(() =>
        registros.value?.filter(
          (data) =>
            !search.value ||
            textSearch(data.responsable, search.value) ||
            textSearch(data.familia?.dni, search.value) ||
            textSearch(data.menoresNombres, search.value) ||
            textSearch(data.direccion, search.value) ||
            textSearch(data.estado, search.value)
        )
      ),
      csvLabels,
      csvFields,
      csvName,
      handleEdit,
      sortMenorNombreApellidos,
      search,
      toLocaleDateString,
    };
  },
});

import Swal from "sweetalert2/dist/sweetalert2.js";

export function showBackendError(t, error) {
  return Swal.fire({
    text: t("errors." + (error.code ?? error.name)) ?? error.name,
    icon: "error",
    buttonsStyling: false,
    confirmButtonText: t("dialogs.okButton"),
    customClass: {
      confirmButton: "btn btn-primary",
    },
  });
}
